import { graphql, Link } from "gatsby";
import * as React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/services.scss";
import GatsbyImage from "gatsby-image";
import CommonSection from "../components/CommonSection";
import ClientLogoBanner from "../components/ClientLogoBanner";
import ButtonBanner from "../components/ButtonBanner";
import StatColumn from "../components/StatColumn";
import CaseStudy from "../sections/homepage/CaseStudy";
import Testimonials from "../sections/homepage/Testimonials";
import Blogs from "../sections/homepage/Blogs";
import AccordionSection from "../components/AccordionSection";
import VideoPlayer from "../components/VideoPlayer/VideoPlayer";

const Services = ({ data }) => {
  let advantages = [
    {
      title: "Get online in minutes",
      sub: "Our platform transforms your website almost instantly. Provide AR/ VR experience within a few minutes.",
      icon: "../../../../icons/advantage/web.svg",
    },
    {
      title: "Easy integration",
      sub: "Opt for Code, API, or SDK style integration. We have ensured flexibility, scale, and ease.",
      icon: "../../../../icons/advantage/code.svg",
    },
    {
      title: "Cloud storage for lightning speed",
      sub: "Every image is stored on the cloud to maintain the speed and performance of your website.",
      icon: "../../../../icons/advantage/scale.svg",
    },
    {
      title: "A gallery of predesigned VR templates",
      sub: "We can custom create VR templates for you to choose from.",
      icon: "../../../../icons/advantage/atom.svg",
    },
  ];
  return (
    <Layout>
      <Helmet>
        <title>HelloAR: Virtual and Augmented Reality Apps & Software</title>
        <meta
          name="description"
          content="Our virtual and augmented reality software empowers your online retail store with experiential buying in minutes"
        />
        <meta
          name="keywords"
          content="augmented reality and virtual reality, augmented reality apps, augmented reality applications, virtual and augmented reality"
        />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />
        <meta
          property="og:image"
          content="../../videos/thumbnail/configurator.jpg"
        />
      </Helmet>

      <Container fluid className="services">
        <div className="spacer"></div>
        <div className="spacer d-xl-none"></div>
        <div className="spacer-sm"></div>
        <CommonSection
          title="Attract. Engage. Convert."
          buttonText="REQUEST A DEMO"
          sub="Experience upto 40% increase in conversions by implementing AR VR solutions on your website"
        >
          <VideoPlayer
            videoUrl="../../videos/virtual-and-augmented-reality.mp4"
            videoThumbnail="../../images/thumbnail/arvr.webp"
          />
        </CommonSection>
        <div className="spacer-sm"></div>
        <ClientLogoBanner />
        <div className="spacer"></div>
        <CommonSection
          title="AR solution"
          sub="Empower your users to place the products in their own spaces and allow for more informed purchases."
          buttonText="REQUEST A DEMO"
          inverted
        >
          <Image src="/images/arvrsolutions/augmented-reality-apps.jpg" fluid />
        </CommonSection>
        <div className="spacer"></div>

        <StatColumn
          title="Why implement AR solution?"
          items={[
            {
              stat: "61%",
              info: "Customers prefer AR Experiences in Retail",
            },
            {
              stat: "63%",
              info: "Customers claim AR improves Shopping Experience",
            },

            {
              stat: "40%",
              info: "Consumers would pay more for Product Customization in AR",
            },
          ]}
        />
        <div className="spacer"></div>
        <CommonSection
          title="VR solution"
          sub="Virtual reality is an artificial environment that replaces a real environment. Like Metaverse. Help users experience & visualize the product virtually."
          buttonText="REQUEST A DEMO"
          inverted
        >
          <Image
            src="/images/arvrsolutions/virtual-reality-shopping.jpg"
            fluid
          />
        </CommonSection>
        <div className="spacer"></div>
        <StatColumn
          title="Why implement VR solution?"
          items={[
            {
              stat: "94%",
              info: "Higher conversions with VR/AR",
            },
            {
              stat: "80%",
              info: "Consumers feel positive about VR experiences",
            },
            {
              stat: "13%",
              info: "Customers will shop more with VR experiences",
            },
          ]}
        />
        {/* <div className="spacer"></div>
        <CaseStudy /> */}
        <div className="spacer"></div>
        <ButtonBanner buttonTitle="REQUEST A DEMO" buttonUrl="/contact-us">
          <h1 className="h1 text-center text-light">
            One stop solution.
            <br />
            Zero upfront cost. Pay as you go.
          </h1>
        </ButtonBanner>
        <div className="spacer"></div>
        <AccordionSection
          title="HelloAR advantages"
          items={[
            {
              image: "../images/arvrsolutions/ar-vr-companies.png",
              imageWebp: "../images/arvrsolutions/ar-vr-companies.webp",
              title: "Get online in minutes",
              sub: "Our platform transforms your website almost instantly. Provide AR/ VR experience within a few minutes.",
            },
            {
              image: "../images/arvrsolutions/ar-vr-companies.png",
              imageWebp: "../images/arvrsolutions/ar-vr-companies.webp",
              title: "Easy integration",
              sub: "Opt for Code, API, or SDK style integration. We have ensured flexibility, scale, and ease.",
            },
            {
              image: "../images/arvrsolutions/ar-vr-companies.png",
              imageWebp: "../images/arvrsolutions/ar-vr-companies.webp",
              title: "Cloud storage for lightning speed",
              sub: "Every image is stored on the cloud to maintain the speed and performance of your website.",
            },
            {
              image: "../images/arvrsolutions/ar-vr-companies.png",
              imageWebp: "../images/arvrsolutions/ar-vr-companies.webp",
              title: "A gallery of predesigned VR templates",
              sub: "We can custom create VR templates for you to choose from.",
            },
          ]}
          mainIndex={3}
        />
        <div className="spacer"></div>

        <Testimonials />
        {/* <div className="spacer"></div> */}
        {/* <Blogs /> */}
        {/* <div className="spacer"></div> */}
      </Container>
    </Layout>
  );
};

// export const imgs = graphql`
//   query {
//     imageOne: file(
//       relativePath: { eq: "arvrsolutions/augmented-reality-apps.jpg" }
//     ) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     imageTwo: file(
//       relativePath: { eq: "arvrsolutions/virtual-reality-shopping.jpg" }
//     ) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `;

export default Services;
